<!--
 * @Author: dingguowei
 * @Date: 2023-06-07 15:03:07
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-20 11:27:22
-->
<!-- 审核任务管理 -->
<template>
  <div class="auditMgt">
    <div class="head">
      <div
        class="headitem"
        v-for="(item, index) in headData"
        :key="index"
        @click="changeStatus(item.status)"
        :style="[
          {
            backgroundImage:
              status !== item.status
                ? `url(${item.bgcUrl})`
                : `url(${item.activebgcUrl})`,
          },
          { color: status === item.status ? '#fff' : '#000' },
          { marginRight: index === headData.length - 1 ? '0px' : '10px' },
        ]"
      >
        <span>{{ item.name }}</span>
        <span>{{ numberData[item.fieId] }}</span>
      </div>
    </div>
    <div class="option">
      <!-- <el-input
        v-model="auditName"
        placeholder="任务名称"
        size="mini"
        style="width: 8rem; margin-right: 2rem"
      ></el-input> -->
      <el-input
        v-model="userName"
        placeholder="领取者名称"
        size="mini"
        style="width: 8rem; margin-right: 2rem"
      ></el-input>
      <el-date-picker
        size="mini"
        v-model="dateRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="任务领取开始日期"
        end-placeholder="任务领取结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        style="margin-right: 2rem"
      >
      </el-date-picker>
      <el-button size="mini" type="primary" @click="getaudittask(true)"
        >搜索</el-button
      >
      <el-button size="mini" type="primary" @click="reset">重置</el-button>
    </div>
    <div class="tabledata">
      <el-table
        :data="tableData"
        :key="tableKey"
        id="table"
        style="width: 100%; font-size: 0.8rem; flex: 1"
        v-loading="loading"
        height="100%"
      >
        <el-table-column
          label="序号"
          type="index"
          width="80"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column
          label="任务名称"
          :show-overflow-tooltip="true"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.missionTitle }}
          </template>
        </el-table-column>
        <el-table-column
          label="领取者名称"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.receiverAccount }}
          </template>
        </el-table-column>
        <el-table-column
          label="用户提交状态"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ statusText(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          label="最终提交时间"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.updatedOn }}
          </template>
        </el-table-column>
        <el-table-column label="提交链接" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <a :href="scope.row.annex" target="_blank" style="width: 100%">
              {{ scope.row.annex }}</a
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <span
              v-if="scope.row.status === 'TOJUDGE'"
              class="handel"
              @click="auditTask(scope.row.id)"
              >审核</span
            >
            <el-divider
              v-if="scope.row.status === 'TOJUDGE'"
              direction="vertical"
            ></el-divider>
            <span class="handel" @click="searcktask(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNo"
        :page-size="pageSize"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :close-on-click-modal="false"
    >
      <div slot="title" class="header-title">
        <span class="name" style="color: black; font-size: 0.8rem"
          >任务审核</span
        >
      </div>
      <div class="dialogbody">
        <div class="left">
          <task_tabs_result
            :mission-receive-id="missionReceiveId"
          ></task_tabs_result>
        </div>
        <div class="right">
          <el-radio-group v-model="auditDialog.handelStatus" size="mini">
            <el-radio label="FINISHED" border>通过</el-radio>
            <el-radio label="REFUSED" border>拒绝</el-radio>
            <el-radio label="FORBID" border>拉黑</el-radio>
            <el-radio label="CLOSED" border>关闭</el-radio>
          </el-radio-group>
          <div class="handle">
            <el-input
              size="mini"
              v-if="auditDialog.handelStatus === 'REFUSED'"
              type="textarea"
              :rows="4"
              placeholder="拒绝理由"
              v-model="auditDialog.refusecomment"
            >
            </el-input>
            <el-input
              size="mini"
              v-if="auditDialog.handelStatus === 'FORBID'"
              type="textarea"
              :rows="4"
              placeholder="拉黑理由"
              v-model="auditDialog.blockcomment"
            >
            </el-input>
            <el-input
              size="mini"
              v-if="auditDialog.handelStatus === 'CLOSED'"
              type="textarea"
              :rows="4"
              placeholder="关闭理由"
              v-model="auditDialog.closecomment"
            >
            </el-input>
          </div>
          <el-button
            v-if="auditDialog.handelStatus !== null"
            class="btn"
            size="mini"
            @click="commit"
            >提交</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import Clipboard from "clipboard";
import _ from "lodash";
import task_tabs_result from "@/components/task/task_tabs_result.vue";
import { getaudittask, queryQuantiy } from "@/api/audit";
import { blocktask, refusetask, passtask } from "@/api/audit";
import { closejoinuser } from "@/api/home";
export default {
  components: {
    task_tabs_result,
  },
  data() {
    return {
      headData: [
        {
          name: "全部提交",
          fieId: "totalNum",
          bgcUrl: require("@/assets/audit/1.png"),
          activebgcUrl: require("@/assets/audit/1active.png"),
          status: null,
        },
        {
          name: "待审核",
          fieId: "submittedNum",
          bgcUrl: require("@/assets/audit/2.png"),
          activebgcUrl: require("@/assets/audit/2active.png"),
          status: "TOJUDGE",
        },
        {
          name: "通过",
          fieId: "finishedNum",
          bgcUrl: require("@/assets/audit/3.png"),
          activebgcUrl: require("@/assets/audit/3active.png"),
          status: "FINISHED",
        },
        {
          name: "拒绝",
          fieId: "refusedNum",
          bgcUrl: require("@/assets/audit/4.png"),
          activebgcUrl: require("@/assets/audit/4active.png"),
          status: "REFUSED",
        },
        {
          name: "拉黑",
          fieId: "forbidNum",
          bgcUrl: require("@/assets/audit/5.png"),
          activebgcUrl: require("@/assets/audit/5active.png"),
          status: "FORBID",
        },
      ],

      userName: "", //领取者名称
      dateRange: [], //日期范围
      tableData: [], //表格数据
      tableKey: true,
      numberData: [],
      status: null,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      centerDialogVisible: false,
      // 审核弹窗内容
      auditDialog: {
        handelStatus: null,
        money: "",
        refusecomment: "",
        blockcomment: "",
        closecomment: "",
      },
    };
  },
  created() {},
  async mounted() {
    await this.queryQuantiy();
    await this.getaudittask();
  },
  watch: {},
  computed: {
    statusText() {
      return function (val) {
        if (val === "TOJUDGE") {
          return "待审核";
        } else if (val === "CLOSED") {
          return "已关闭";
        } else if (val === "FINISHED") {
          return "通过";
        } else if (val === "REFUSED") {
          return "拒绝";
        } else if (val === "FORBID") {
          return "拉黑";
        }
      };
    },
    missionReceiveId() {
      return store.state.task.taskInfo.missionReceiveId;
    },
    missionId() {
      return store.state.task.taskInfo.missionId;
    },
  },
  methods: {
    // 查询审核任务
    async getaudittask(val) {
      this.loading = true;
      await getaudittask({
        startAt: this.dateRange[0],
        endAt: this.dateRange[1],
        receiverKwd: this.userName,
        missionId: this.missionId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        status: this.status,
      }).then((res) => {
        this.loading = false;
        this.tableKey = !this.tableKey;
        this.tableData = res.data.records;
        this.total = res.data.total;
        if (val) {
          this.$message({
            message: "搜索成功！",
            type: "success",
          });
        }
      });
    },
    // 查询审核任务数量
    async queryQuantiy() {
      await queryQuantiy({
        missionId: this.missionId,
      }).then((res) => {
        this.numberData = res.data;
        this.numberData.total =
          res.data.finishedNum +
          res.data.forbidNum +
          res.data.refusedNum +
          res.data.submittedNum;
      });
    },
    reset() {
      (this.userName = ""), (this.dateRange = []);
    },
    async changeStatus(val) {
      (this.status = val), await this.getaudittask(true);
    },
    handleCurrentChange() {
      this.getaudittask();
    },
    //查看任务详情
    searcktask(val) {
      store.commit("task/CHANGE_MISSIONID", val.missionId);
      store.commit("task/CHANGE_RECEIVERID", val.receiverId);
      store.commit("task/CHANGE_missionReceiveId", val.id);
      router.push({
        name: "审核任务详情",
        params: {
          status: val.status,
        },
      });
    },
    auditTask(val) {
      this.auditDialog.handelStatus = null;
      this.auditDialog.money = null;
      this.auditDialog.refusecomment = null;
      this.auditDialog.blockcomment = null;
      this.centerDialogVisible = true;
      store.commit("task/CHANGE_missionReceiveId", val);
    },
    // 提交审核
    commit() {
      switch (this.auditDialog.handelStatus) {
        case "FINISHED":
          this.passtask();
          break;
        case "REFUSED":
          this.refusetask();
          break;
        case "FORBID":
          this.blocktask();
          break;
        case "CLOSED":
          this.closetask();
          break;
        default:
          break;
      }
    },
    // 各种审核操作
    // 不通过操作
    async refusetask() {
      await refusetask({
        missionReceiveId: this.missionReceiveId,
        endorse: this.auditDialog.refusecomment,
      }).then((res) => {
        if (res.code === 1000) {
          this.centerDialogVisible = false;
          this.queryQuantiy();
          this.getaudittask();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 通过操作
    async passtask() {
      await passtask({
        missionReceiveId: this.missionReceiveId,
        // salary: this.auditDialog.money,
      }).then((res) => {
        if (res.code === 1000) {
          this.centerDialogVisible = false;
          this.queryQuantiy();
          this.getaudittask();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 拉黑操作
    async blocktask() {
      await blocktask({
        missionReceiveId: this.missionReceiveId,
        endorse: this.auditDialog.blockcomment,
      }).then((res) => {
        if (res.code === 1000) {
          this.centerDialogVisible = false;
          this.queryQuantiy();
          this.getaudittask();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 关闭内容
    async closetask() {
      closejoinuser({
        missionReceiveId: this.missionReceiveId,
        endorse: this.auditDialog.closecomment,
      }).then((res) => {
        if (res.code === 1000) {
          this.queryQuantiy();
          this.getaudittask();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.auditMgt {
  .el-radio.is-bordered + .el-radio.is-bordered {
    margin: 0;
  }
  ::v-deep .el-radio {
    margin: 0;
  }
  box-sizing: border-box;
  padding: 1rem 2rem;
  user-select: text;
  display: flex;
  flex-direction: column;

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 14px;
  }

  ::v-deep .el-button {
    border: none;
    background-color: #fff;
    border-radius: 14px;
    color: #000;
  }

  ::v-deep .el-button--primary.is-active,
  .el-button--primary:active {
    background: #587aef;
    color: #fff;
  }

  ::v-deep .el-button--primary:focus,
  .el-button--primary:hover {
    background: #587aef;
    color: #fff;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(28, 28, 28);
    /*进行修改选中项背景和字体*/
    color: #fff;
  }

  .head {
    display: flex;

    .headitem {
      cursor: pointer;
      flex: 1;
      height: 130px;
      margin-right: 20px;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
    }

    & span:first-child {
      padding: 0.6rem 1rem;
      font-size: 0.8rem;
      height: 2rem;
      line-height: 2rem;
    }

    & span:last-child {
      font-weight: 600;
      flex: 1;
      font-size: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .option {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }

  .tabledata {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    ::v-deep .el-table__body-wrapper {
      overflow-y: auto;
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .circle {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .handel {
      cursor: pointer;
    }

    .handel:hover {
      color: #587aef;
    }

    .pagination {
      margin-top: 10px;
      margin-left: auto;
    }
  }

  .dialogbody {
    display: flex;

    .left {
      height: 50vh;
      overflow: auto;
      flex: 1;
    }

    .right {
      width: 20rem;
      min-width: 300px;
      display: flex;
      flex-direction: column;

      align-items: center;

      ::v-deep .el-textarea__inner {
        //el_input中的隐藏属性
        resize: none; //主要是这个样式
        font-size: 0.8rem;
        background-color: #f7f6f4;
      }

      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-input__inner {
        background-color: #f7f6f4;
        font-size: 0.8rem;
        border-radius: 0;
      }

      .handle {
        margin-top: 20px;
        text-align: center;
        flex: 1;
        width: 100%;
      }

      .btn {
        width: 80%;
        background-color: #5874ef;
        color: #fff;
      }
    }
  }
}
</style>